<template>
    <div>
        <ErrorMessages :errors="errors"></ErrorMessages>
    </div> 
</template>

<script>

export default {
    name: 'Hidden',
    props: {
        value: {
            type: [String, Number, Boolean, Array, Object],
            default: null
        },
        required: Boolean,
        errors: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'change'
    ],
    data() {
        return {
            newValue: null,
        }
    },
    methods: {
        emitValue() {
            this.$emit('change', this.newValue)       
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(newVal) {
              this.newValue = newVal;
            }
        },
        newValue() {
            this.$emit('change', this.newValue)
        }

    },
}

</script>

<style lang="scss">

</style>